import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    AcknowledgeLogicEvent,
    InitContactControlDevice,
    InitDataloggerDevice,
    InitLimitArray,
    RevertValueInDatalogger,
    SetNewValueInDatalogger,
    UpdateContactControlDevice,
    UpdateDataloggerDevice,
} from '../actions/details-device-datalogger.actions';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { VariablesNameEnum } from '../../../app-shared-elements/_enums/variables-name.enum';
import { DetailsDeviceDataloggerService } from '../../_services/details-device-datalogger.service';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { LogicEventType } from '../../../events/logical-events/_interface/LogicEvent';
import { DataloggerDeviceInterface } from '../../_interface/datalogger-device.interface';
import { LimitArrayInterface } from '../../_interface/limit-array.interface';
import { TransportDashboardService } from '../../../transport-container/transport-dashboard/_services/transport-dashboard.service';
import { ContactControlArray } from '../../_interface/contact-control-array.interface';

export interface DetailsDeviceDataloggerStateModel {
    currentDevice: DataloggerDeviceInterface;
    limitArray: LimitArrayInterface[];
    contactControlArray: ContactControlArray[];
}

const DETAILS_DEVICE_DATALOGGER_TOKEN = new StateToken<DetailsDeviceDataloggerStateModel>('detailsDeviceDatalogger');

@State<DetailsDeviceDataloggerStateModel>({
    name: DETAILS_DEVICE_DATALOGGER_TOKEN,
    defaults: {
        currentDevice: null,
        limitArray: [],
        contactControlArray: [],
    },
})
@Injectable()
export class DetailsDeviceDataloggerState {
    constructor(
        private store: Store,
        private detailsDeviceDataloggerService: DetailsDeviceDataloggerService,
        private http: HttpClient,
        private notificationsService: NotificationsService,
        private transportDashboardService: TransportDashboardService,
    ) {}

    @Selector()
    static getCurrentDevice(state: DetailsDeviceDataloggerStateModel): DataloggerDeviceInterface {
        return state.currentDevice;
    }

    @Selector()
    static getLimitArray(state: DetailsDeviceDataloggerStateModel): LimitArrayInterface[] {
        return state.limitArray;
    }

    @Selector()
    static getContactControlArray(state: DetailsDeviceDataloggerStateModel): ContactControlArray[] {
        return state.contactControlArray.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    @Action(InitDataloggerDevice)
    initDataloggerDevice(ctx: StateContext<DetailsDeviceDataloggerStateModel>): void {
        const state = ctx.getState();
        const currentDevice = this.store.selectSnapshot(DevicesState.getCurrentDevice);
        const currentModification = this.store.selectSnapshot(DevicesState.getCurrentModification);

        ctx.setState({
            ...state,
            currentDevice: {
                ...currentDevice,
                periodArchive: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ArchivePeriod),
                VariaficationDate: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.VerificationDate),
                ArchiveMode: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ArchiveMode),
                signal: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.RSSI),
                battery: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.BatteryLevel),
                ButtonMode: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ButtonMode) ?? null,
                EventStatus: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.EventStatus),
                ArchiveLaunchMode: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ArchiveLaunchMode),
                ArchiveRequested: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ArchiveRequested),
                ArchiveStart: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ArchiveStart),
                ArchiveStop: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ArchiveStop),
                ArchiveDelay: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ArchiveDelay),
                EventDelay: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.EventDelay),
                EventRequested: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.EventRequested),
                ArchiveStatus: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ArchiveStatus),
                temperature: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.Temperature1),
                temperature1: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.Temperature2),
                humidity: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.Humidity),
                Modification: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.Modification),
                IsTransport: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.IsTransport),
                Contact1: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.Contact1),
                Contact2: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.Contact2),
                Contact3: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.Contact3),
                Contact4: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.Contact4),
                ContactPeriod: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.ContactPeriod),
                LogicDelay: this.detailsDeviceDataloggerService.getCurrentVariableValue(currentDevice, VariablesNameEnum.LogicDelay),
            },
        });
    }

    @Action(UpdateDataloggerDevice)
    updateDataloggerDevice(ctx: StateContext<DetailsDeviceDataloggerStateModel>, payload: UpdateDataloggerDevice): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            currentDevice: {
                ...state.currentDevice,
                [payload.name]: {
                    ...state.currentDevice[payload.name],
                    value: payload.value ?? state.currentDevice[payload.name].value,
                },
            },
        });
    }

    @Action(SetNewValueInDatalogger)
    async setNewValueInDatalogger(ctx: StateContext<DetailsDeviceDataloggerStateModel>, payload: SetNewValueInDatalogger): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/groups/set-variables-values-all', { data: [...payload.data] })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();
        // console.log(result);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            result.data.forEach((item) => {
                state.currentDevice = {
                    ...state.currentDevice,
                    [item.name]: {
                        ...state.currentDevice[item.name],
                        ...item,
                        value: this.detailsDeviceDataloggerService.parseVariableType(item),
                    },
                };
            });

            ctx.setState({
                ...state,
                currentDevice: state.currentDevice,
                contactControlArray: state.contactControlArray.map((item) => {
                    return {
                        ...item,
                        variables: item.variables.map((variable) => {
                            const currentVariable = payload.data.find((f) => f.id === variable.id);
                            if (!currentVariable) {
                                return variable;
                            }

                            return {
                                ...variable,
                                currentValue: currentVariable.value,
                            };
                        }),
                    };
                }),
            });
        }
    }

    @Action(RevertValueInDatalogger)
    async revertValueInDatalogger(ctx: StateContext<DetailsDeviceDataloggerStateModel>, payload: RevertValueInDatalogger): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/groups/revert-variable', { variableId: payload.variableId })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                currentDevice: {
                    ...state.currentDevice,
                    [result.data.name]: {
                        ...state.currentDevice[result.data.name],
                        value: result.data.name === VariablesNameEnum.ArchiveStart || result.data.name === VariablesNameEnum.ArchiveStop ? +result.data.currentValue * 1000 : result.data.currentValue,
                    },
                },
                contactControlArray: state.contactControlArray.map((item) => {
                    return {
                        ...item,
                        variables: item.variables.map((row) => {
                            console.log(row, result.data);
                            if (row.id === result.data.id) {
                                return {
                                    ...result.data,
                                    currentValue: result.data.currentValue,
                                    savedStatusValue: result.data.savedStatusValue,
                                };
                            }

                            return row;
                        }),
                    };
                }),
            });
        }
    }

    @Action(AcknowledgeLogicEvent)
    async acknowledgeLogicEvent(ctx: StateContext<DetailsDeviceDataloggerStateModel>, payload: AcknowledgeLogicEvent): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/logic-events/acknowledge', {
                id: payload.id,
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, `${result.status}`);
        }
    }

    @Action(InitLimitArray)
    initLimitArray(ctx: StateContext<DetailsDeviceDataloggerStateModel>, payload: InitLimitArray): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            limitArray: payload.expeditions
                .map((item) => {
                    const currentVariable = this.store
                        .selectSnapshot(DetailsDeviceDataloggerState.getCurrentDevice)
                        .variables.filter((f) => f.hasEvent)
                        .find((v) => v.id === item.variableId);
                    if (!currentVariable) {
                        return;
                    }
                    return {
                        ...item,
                        title: currentVariable.customName ?? currentVariable.name,
                        name: currentVariable.name,
                        currentValue: currentVariable.currentValue,
                        unitName: currentVariable.unitName,
                        internalId: currentVariable.internalId,
                        isHumidity: currentVariable.name === VariablesNameEnum.HumidityValue,
                        restriction: currentVariable.restriction?.split(':').map(parseFloat),
                        eventInEdit: currentVariable.eventInEdit,
                        isAcknowledgeable:
                            payload.expeditions.filter(
                                (f) =>
                                    f.variableId === item.variableId &&
                                    (f[LogicEventType.alarmDeadlineMax]?.isAcknowledgeable ||
                                        f[LogicEventType.alarmDeadlineMin]?.isAcknowledgeable ||
                                        f[LogicEventType.attentionDeadlineMax]?.isAcknowledgeable ||
                                        f[LogicEventType.attentionDeadlineMin]?.isAcknowledgeable),
                            ).length > 0,
                        [LogicEventType.alarmDeadlineMin]: this.transportDashboardService.getValueFoDatalogger(item, LogicEventType.alarmDeadlineMin),
                        [LogicEventType.alarmDeadlineMax]: this.transportDashboardService.getValueFoDatalogger(item, LogicEventType.alarmDeadlineMax),
                        [LogicEventType.attentionDeadlineMin]: this.transportDashboardService.getValueFoDatalogger(item, LogicEventType.attentionDeadlineMin),
                        [LogicEventType.attentionDeadlineMax]: this.transportDashboardService.getValueFoDatalogger(item, LogicEventType.attentionDeadlineMax),
                    };
                })
                .filter((f) => !!f)
                .sort((a, b) => (a.internalId > b.internalId ? 1 : -1)),
        });
    }

    @Action(InitContactControlDevice)
    initContactControlDevice(ctx: StateContext<DetailsDeviceDataloggerStateModel>, payload: InitContactControlDevice): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            contactControlArray: payload.currentDevice.variables
                .filter((f) => f.name === VariablesNameEnum.Contact1 || f.name === VariablesNameEnum.Contact2 || f.name === VariablesNameEnum.Contact3 || f.name === VariablesNameEnum.Contact4)
                .map((item) => {
                    const variableNumber = item.name.slice(-1);
                    return {
                        name: item.customName?.slice(0, 8) ?? item.name?.slice(0, 8),
                        id: item.id,
                        variables: payload.currentDevice.variables
                            .filter((f) => f.name === `Contact${variableNumber}TriggerStatus` || f.name === `Contact${variableNumber}EventDelay` || f.name === `Contact${variableNumber}EventEnabled`)
                            .map((item, index) => {
                                return {
                                    ...item,
                                    currentValue: this.detailsDeviceDataloggerService.parseVariableType(item),
                                    index: item.name === `Contact${variableNumber}TriggerStatus` ? 0 : item.name === `Contact${variableNumber}EventDelay` ? 1 : 2,
                                };
                            })
                            .sort((a, b) => +a.index - +b.index),
                    };
                }),
        });
    }

    @Action(UpdateContactControlDevice)
    updateContactControlDevice(ctx: StateContext<DetailsDeviceDataloggerStateModel>, payload: UpdateContactControlDevice): void {
        const state = ctx.getState();

        const variables = payload.currentDevice.variables.filter(
            (f) => f.name === VariablesNameEnum.Contact1 || f.name === VariablesNameEnum.Contact2 || f.name === VariablesNameEnum.Contact3 || f.name === VariablesNameEnum.Contact4,
        );

        ctx.setState({
            ...state,
            contactControlArray: state.contactControlArray.map((array) => {
                const currentArray = variables.find((f) => f.id === array.id);
                const variableNumber = array.name.slice(-1);
                return {
                    ...array,
                    name: currentArray.customName ?? currentArray.name,
                    variables: array.variables.map((variable) => {
                        const currentVariable = payload.currentDevice.variables.find((f) => f.id === variable.id);

                        return {
                            ...currentVariable,
                            currentValue: this.detailsDeviceDataloggerService.parseVariableType(variable),
                            index: variable.name === `Contact${variableNumber}TriggerStatus` ? 0 : variable.name === `Contact${variableNumber}EventDelay` ? 1 : 2,
                        };
                    }),
                };
            }),
        });
    }
}

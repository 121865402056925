import { Injectable } from '@angular/core';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { VariablesNameEnum } from '../../app-shared-elements/_enums/variables-name.enum';
import { Variable, VariableType } from '../../app-shared-elements/_interfaces/Variable';

@Injectable({
    providedIn: 'root',
})
export class DetailsDeviceDataloggerService {
    constructor() {}

    getCurrentVariableValue(currentDevice: Device, name: VariablesNameEnum): any {
        const currentVariable = currentDevice?.variables?.find((v) => v.name === name);
        if (!currentDevice || !currentVariable || currentVariable.currentValue === null) {
            return null;
        }

        return {
            ...currentVariable,
            value: this.parseVariableType(currentVariable),
            valueToSave: this.parseVariableType(currentVariable),
        };
    }

    parseVariableType(variable: Variable): any {
        if (variable.name === VariablesNameEnum.VerificationDate) {
            return variable?.valueToSave.length ? variable?.valueToSave : variable?.currentValue;
        }
        switch (variable?.type) {
            case VariableType.BOOL:
                return variable?.valueToSave.length ? variable?.valueToSave.includes('true') ?? !!variable?.valueToSave : !!variable?.currentValue;
            case VariableType.DATE:
                return variable?.valueToSave.length ? +variable?.valueToSave * 1000 : +variable?.currentValue * 1000;
            default:
                return variable?.valueToSave?.length ? variable?.valueToSave : variable?.currentValue;
        }
    }
}
